import React,  { useState } from "react";
import Header from "../components/header/header";
import Helmet from "react-helmet";
import {Link} from "gatsby";

export default function Editorial(props) {
  const [open, setOpen] = useState(false);
  return (
    <div className="index">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Editorial</title>
      </Helmet>
      <Header page='editorial' open={open} setOpen={setOpen}/>
      <div className='content'>
        <div className="grid">
        <div className='pics'>
          <img src={'/forbes.jpg'} alt='forbes'></img>
          <img src={'/download.png'} alt='download'></img>
          <img src={'/bmo.gif'} alt='bmo'></img>
          <img src={'/book.jpg'} alt='book'></img>
          </div>
        <div className='words'>
      <p className='indent'>I’m a writer, editor, and proofreader with nearly a decade of experience creating and reviewing original content with a focus on fields such as education, culture, and finance. In addition to digital and print editorial work, I have a strong background in marketing and project management, copywriting for magazines and brands, managing a wide net of clients, and developing partnerships in the publishing, non-profit, and media industries. My clients include Forbes, Scholastic, and the Bank of Montreal.</p>
      <br></br>
      <p className='indent'>I am available to work as a teaching artist or guest writer. In Fall 2021, I was selected as a visiting poet in the Working Poets Project at The Dalton School, where I participated in discussions and analysis of my work with sophomore level Intro to Poetry classes. I have taught creative writing lessons for schools and organizations such as The Center for the Arts Eagle Rock, Guildford County Schools, and Poly Prep Country Day School. I also lead weekly Zoom poetry workshops for all ages.
</p>
      <br></br>
      <p className='indent'>You can purchase The Book of Common Fallacies, a second edition volume I co-wrote, researched, and edited in 2012 through Skyhorse Publishing.</p>
      <br></br>
      
      <p>
      Download my <Link to='/resume'>cv/resume</Link> for details or get in touch here: 

      <a href='mailto:juliacedwards4489@gmail.com'> juliacedwards4489@gmail.com</a>
      </p>
      <img src={'/finalpoetry.jpg'} alt='finalpoetry'></img>

        </div>
        
        </div>
      </div>
    </div>
  )
}
